
.border-left { border-left: 1px solid @brand-primary; }


div[class*="border-left-xs"]{  padding-left: ( @grid-gutter-width * @xs-pad );}
div[class*="border-left-md"]{ padding-left: ( @grid-gutter-width * @md-pad );}
div[class*="border-left-lg"]{ padding-left: ( @grid-gutter-width * @lg-pad );}
div[class*="border-left-xl"]{ padding-left: ( @grid-gutter-width * @xl-pad );}

.border-left-xs,
.border-left-md,
.border-left-lg,
.border-left-xl
{
  border-left: .75em solid ;
}




.flex-container-mixin(@screen-min,@name) {
  @spec: ~"@{name}";
  // No Wrapping Solutions

  .flex-@{spec}-stretch-nowrap {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: stretch;
      flex-wrap: nowrap;
    }
  }
  .flex-@{spec}-center-nowrap {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
  }
  .flex-@{spec}-start-nowrap {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: flex-start;
      flex-wrap: nowrap;
    }
  }
  .flex-@{spec}-end-nowrap {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: flex-end;
      flex-wrap: nowrap;
    }
  }


  .flex-@{spec}-stretch {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
    }
  }
  .flex-@{spec}-center {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .flex-@{spec}-start {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
  .flex-@{spec}-end {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
    }
  }
  .flex-@{spec}-baseline {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
    }
  }
  .flex-@{spec}-baseline-last {
    display:block;
    @media (min-width: @screen-min) {
      display: flex;
      align-items: last-baseline;
      flex-wrap: wrap;
    }
  }

  .flex-@{spec}-reset {
    display:block;
    @media (min-width: @screen-min) {
      display: block;
    }
  }

}

.flex-container-mixin(0,~'xs');
.flex-container-mixin(@screen-sm-min,~'sm');
.flex-container-mixin(@screen-md-min,~'md');
.flex-container-mixin(@screen-lg-min,~'lg');





@media(max-width: @screen-sm-min) and (min-width: @screen-xs-min){
  .row-md-fifths {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    .column {

      width: 50%;
    }
  }
}
@media(max-width: @screen-md-min) and (min-width: @screen-sm-min){
  .row-md-fifths {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    .column {

      width: 33.3333333%;
    }
  }
}
.fifths-row-mixin(@screen-min,@name) {
  @spec: ~"@{name}";
  // No Wrapping Solutions

  .row-@{spec}-fifths {

    @media (min-width: @screen-min) {
      .column {
        width: 20%;
      }
    }
  }
}
.fifths-row-mixin(0,~'xs');
.fifths-row-mixin(@screen-sm-min,~'sm');
.fifths-row-mixin(@screen-md-min,~'md');
.fifths-row-mixin(@screen-lg-min,~'lg');



