// do find and replace for .icon-i- and replace with *.

// regext this .icon-i_\S+\s+\{\s+\S+\s+\S+\s+\S+\s+\S+\s+\S+\s+
// than this \{\s+\s+\S+\s+}
// than this &.(\S+) with 'b-icon {$1}' => '{$1}',

i.b-icon, span.b-icon {
  font-family: "ICO";
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  width: auto;
  position: relative;
  height: auto;

  &:before {
    font-family: "ICO";
    display: inline-block;
  }

  &.core-brand-mark {
    text-indent: -1em;
    overflow: hidden;
    height: 1em;
    width: 1em;
    line-height: 1em;
    //display: inline-block;
    //margin-right: .25em;
    position: relative;
    vertical-align: baseline;
    &:after {

      position: absolute;

      @icon: 'core-brand-mark';
      @icon-folder: '../images/';
      content: '';
      height: 1em;
      width: 1em;
      left: 0;
      bottom: 0;
      background-repeat: no-repeat;
      line-height: 1em;
      background-size: 100%;
      display: inline-block;
      vertical-align: baseline;
      background: url("@{icon-folder}@{icon}.svg");
      background: url("@{icon-folder}@{icon}3x.png");
      background-size: contain;

    }
  }
  &.bicon-01:before {
    content: "\e900";
  }
  &.bicon-01:before {
    content: "\e900";
  }
  &.bicon-02:before {
    content: "\e901";
  }
  &.bicon-03:before {
    content: "\e902";
  }
  &.bicon-04:before {
    content: "\e903";
  }
  &.bicon-05:before {
    content: "\e904";
  }
  &.bicon-06:before {
    content: "\e905";
  }
  &.bicon-07:before {
    content: "\e906";
  }
  &.bicon-08:before {
    content: "\e907";
  }
  &.bicon-09:before {
    content: "\e908";
  }
  &.bicon-10:before {
    content: "\e909";
  }
  &.bicon-11:before {
    content: "\e90a";
  }
  &.bicon-12:before {
    content: "\e90b";
  }
  &.bicon-13:before {
    content: "\e90c";
  }
  &.bicon-14:before {
    content: "\e90d";
  }
  &.bicon-15:before {
    content: "\e90e";
  }
  &.bicon-16:before {
    content: "\e90f";
  }
  &.bicon-17:before {
    content: "\e910";
  }
  &.bicon-18:before {
    content: "\e911";
  }
  &.bicon-19:before {
    content: "\e912";
  }
  &.bicon-20:before {
    content: "\e913";
  }
  &.bicon-21:before {
    content: "\e914";
  }
  &.bicon-22:before {
    content: "\e915";
  }
  &.bicon-23:before {
    content: "\e916";
  }
  &.bicon-24:before {
    content: "\e917";
  }
  &.bicon-25:before {
    content: "\e918";
  }
  &.bicon-26:before {
    content: "\e919";
  }
  &.bicon-27:before {
    content: "\e91a";
  }
  &.bicon-28:before {
    content: "\e91b";
  }
  &.bicon-29:before {
    content: "\e91c";
  }
  &.bicon-30:before {
    content: "\e91d";
  }
  &.bicon-31:before {
    content: "\e91e";
  }
  &.bicon-32:before {
    content: "\e91f";
  }
  &.bicon-33:before {
    content: "\e920";
  }
  &.bicon-34:before {
    content: "\e921";
  }
  &.bicon-35:before {
    content: "\e922";
  }
  &.bicon-36:before {
    content: "\e923";
  }
  &.bicon-37:before {
    content: "\e924";
  }
  &.bicon-38:before {
    content: "\e925";
  }
  &.bicon-39:before {
    content: "\e926";
  }
  &.bicon-40:before {
    content: "\e927";
  }
  &.bicon-41:before {
    content: "\e928";
  }
  &.bicon-42:before {
    content: "\e929";
  }
  &.bicon-43:before {
    content: "\e92a";
  }
  &.bicon-44:before {
    content: "\e92b";
  }
  &.bicon-45:before {
    content: "\e92c";
  }
  &.bicon-46:before {
    content: "\e92d";
  }
  &.bicon-47:before {
    content: "\e92e";
  }
  &.bicon-48:before {
    content: "\e92f";
  }
  &.bicon-49:before {
    content: "\e930";
  }
  &.bicon-50:before {
    content: "\e931";
  }
  &.bicon-51:before {
    content: "\e932";
  }
  &.bicon-52:before {
    content: "\e933";
  }
  &.bicon-53:before {
    content: "\e934";
  }
  &.bicon-54:before {
    content: "\e935";
  }
  &.bicon-55:before {
    content: "\e936";
  }
  &.bicon-56:before {
    content: "\e937";
  }
  &.bicon-57:before {
    content: "\e938";
  }
  &.bicon-58:before {
    content: "\e939";
  }
  &.bicon-59:before {
    content: "\e93a";
  }
  &.bicon-60:before {
    content: "\e93b";
  }
  &.bicon-61:before {
    content: "\e93c";
  }
  &.bicon-62:before {
    content: "\e93d";
  }
  &.bicon-63:before {
    content: "\e93e";
  }
  &.bicon-64:before {
    content: "\e93f";
  }
  &.bicon-65:before {
    content: "\e940";
  }
  &.bicon-66:before {
    content: "\e941";
  }
  &.bicon-67:before {
    content: "\e942";
  }
  &.bicon-68:before {
    content: "\e943";
  }
  &.bicon-69:before {
    content: "\e944";
  }
  &.bicon-70:before {
    content: "\e945";
  }
  &.bicon-71:before {
    content: "\e946";
  }
  &.bicon-72:before {
    content: "\e947";
  }
  &.bicon-73:before {
    content: "\e948";
  }
  &.bicon-74:before {
    content: "\e949";
  }
  &.bicon-75:before {
    content: "\e94a";
  }
  &.bicon-76:before {
    content: "\e94b";
  }
  &.bicon-77:before {
    content: "\e94c";
  }
  &.bicon-78:before {
    content: "\e94d";
  }
  &.bicon-79:before {
    content: "\e94e";
  }
  &.bicon-80:before {
    content: "\e94f";
  }
  &.bicon-81:before {
    content: "\e950";
  }
  &.bicon-82:before {
    content: "\e951";
  }
  &.bicon-83:before {
    content: "\e952";
  }
  &.bicon-84:before {
    content: "\e953";
  }
  &.bicon-85:before {
    content: "\e954";
  }
  &.bicon-86:before {
    content: "\e955";
  }
  &.bicon-87:before {
    content: "\e956";
  }
  &.bicon-88:before {
    content: "\e957";
  }
  &.bicon-89:before {
    content: "\e958";
  }
  &.bicon-90:before {
    content: "\e959";
  }
  &.bicon-91:before {
    content: "\e95a";
  }
  &.bicon-92:before {
    content: "\e95b";
  }
  &.bicon-93:before {
    content: "\e95c";
  }
  &.bicon-94:before {
    content: "\e95d";
  }
  &.bicon-95:before {
    content: "\e95e";
  }
  &.bicon-96:before {
    content: "\e95f";
  }
  &.bicon-97:before {
    content: "\e960";
  }
  &.bicon-98:before {
    content: "\e961";
  }
  &.bicon-99:before {
    content: "\e962";
  }
  &.bicon-100:before {
    content: "\e963";
  }

}

.size-iterator-icon(@n, @i: 2) when (@i =< @n) {
  i.fa.icon-@{i}x, .b-icon.icon-@{i}x {
    font-size: unit(@i, em);
    line-height: 1;
  }
  .size-iterator-icon(@n, (@i + 1));
}

.size-iterator-icon(5);