


#scrollLockModal {
  display: block;
  position: fixed;
  width: 100%;
  height: 0;
  z-index: 300;
  top: 0;
  left: 0;
  background: #fff;
  opacity: .0;
  cursor: pointer;
  visibility: hidden;
  transition: opacity 0.3s;
  transition-timing-function: ease-in-out;
  &.scrollLock {
    opacity: .35;
    height: 100%;

    visibility: visible;
  }
}

#cta-modal {
  font-size: 16px;
  line-height: @line-height-base;
  box-shadow: 0px 4px 4px 3px fade(@brand-gray-dark, 25);
  @media (max-width: @screen-sm-max) {
    width: 100%;
  }

  // ACTUAL MODAL PROPERTIES -------------------------
  position: fixed;
  max-height: 100%;
  visibility: hidden;
  overflow-y: auto;
  vertical-align: middle;
  display: block;
  top: -500000%;
  left: 50%;
  transform: translate(-50%) translateY(-50%) scale(0, 0);
  z-index: 301;
  padding-top: 0px;
  padding-bottom: 0px;
  transition: transform 0.3s;
  transition-timing-function: ease-in-out;
  background: #fff;
  &.open {
    visibility: visible;
    top: 50%;
    transform: translate(-50%) translateY(-50%) scale(1, 1);
    display: block;

  }

  #closeModal {

    font-size: 1em;
    //background: @brand-secondary;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.6em 0.8em;
    z-index: 22;
    text-align: center;
    background: @brand-primary;
    color: #fff;

    i {
      width: 1em;
      height: 1em;
      color: inherit;
    }
  }
}

#cta-modal {
  -webkit-font-smoothing: antialiased;
  .row.content-row-cta-modal {
    @media (min-width: @screen-xs-max) {
      display: flex;
      align-items: stretch;
    }
    .column.modal-live-area-column {
    }
  }
  .row.content-row-cta-modal {
    .column {
      padding-top: @grid-gutter-width;
      padding-bottom: @grid-gutter-width;
    }
  }
  @media (max-width: @screen-xs-max) {
    width: ~'calc(100% - 40px)';
    .row.content-row-cta-modal {

      .column {
        padding: 20px;
        padding-top: @grid-gutter-width;
        padding-bottom: @grid-gutter-width;
      }
    }
  }

}

#cta-modal {
  form {
    div.validation_error {
      display: none !important;
    }
  }
  form .gform_body {
      padding-left: 0px;
      padding-right: 0px;
    ul.gform_fields {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      margin-left: 0 !important;
      margin-right: 0 !important;

      li.gfield  {
        padding: 5px 10px;
      }
      //li {
      //  &.name-field,&.phone-field,&.company-field,&.email-field {
      //    width: 25%;
      //  }
      //
      //}
      //
      //li.subject-field {
      //  width: 100%;
      //
      //}
      //li.message-field {
      //  width: 100%;
      //}
      //
      li {
        input, textarea, select {
          width: 100%;
          background: @brand-text-on-dark;
          border: 1px solid @text-color !important;
        }
      }
      //@media (max-width: @screen-sm-max) {
      //  li {padding: 10px;}
      //  li {
      //    &.name-field,&.phone-field,&.company-field,&.email-field {
      //      width: 50%;
      //    }
      //
      //  }
      //
      //  li.subject-field {
      //    width: 100%;
      //
      //  }
      //  li.message-field {
      //    width: 100%;
      //  }
      //
      //}
      //@media (max-width: @screen-xs-max) {
      //  li {
      //    &.name-field,&.phone-field,&.company-field,&.email-field {
      //      width: 100%;
      //    }
      //
      //  }
      //
      //  li.subject-field {
      //    width: 100%;
      //
      //  }
      //  li.message-field {
      //    width: 100%;
      //  }
      //
      //}
    }
  }
}

#cta-modal {
  h2.modal-title {
  }

  ul {
    &.icon-leading-list {
      font-size: 1.25em;
    }
    padding-left: 0px;
    list-style: none;
    margin-bottom: 0px;
    li {
      font-weight: 300;

      font-family: @headings-font-family;
      li {
        //padding-left: 1em;
        li {
          //padding-left: 2em;
        }
      }
      a {
        display: inline-block;
        width: 100%;
        padding: 0em;

        &:hover {
          text-decoration: none;
        }
      }

    }
  }
  #cta-logo {
    display: inline-block;
    max-width: 320px;
    width: 100%;
    position: relative;
    display: block;
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);
    background: inherit;
    &:before {
      content: '';
      background: inherit;
      .logo-negative-nomargin();

      padding-bottom: percentage((@footer-logo-height / @footer-logo-width));
      background-size: 100%;
      display: inline-block;
      width: 100%;
    }
  }
}

#cta-modal {
  ul.gform_fields {

  }
}