@header-height-factor : 10;
div.heading-wrap {

  box-sizing: border-box;
  padding-top : (@grid-gutter-width / 2);
  padding-bottom : (@grid-gutter-width / 2);

  height: (@grid-gutter-width * @header-height-factor);
  display: flex;
  align-items: flex-end;

  background-size: cover;
  background-position:center center;
  //box-shadow: inset 0px -85px 30px -24px #ffffff;

  overflow: hidden;
  position:relative;
}