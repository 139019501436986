footer {
  font-size: 14px;
  display: block;
  float: left;
  position: relative;
  width: 100%;
  a {
    color: @brand-primary;
  }
  h1,h2,h3,h4,h5,h6 {
    color: @brand-text-on-dark;
    font-size: @font-size-h5;
    font-weight: @headings-font-weight;
    padding-bottom: (@grid-gutter-width * @heading-margin-factor * .25);
    border-bottom: 2px solid @brand-primary;
    margin-bottom: (@grid-gutter-width * @heading-margin-factor * .75);
  }
  color: #fff;
  .logo-link #footer-logo {
    height: @footer-logo-height;
    width: @footer-logo-width;
    position: relative;
    background: @footer-logo-fallback, @footer-logo-color-fallback;
    background: @footer-logo, @footer-logo-color-fallback;
    background-size: cover;
    display: block;
    margin-bottom: (@grid-gutter-width * @heading-margin-factor);
  }

  @media ( min-width: @screen-lg-min) {
    .logo-container {
      width: auto;
    }
  }

  @media ( max-width: @screen-md-min) {
    .logo-link {
      display: inline-block;
    }

    .logo-container {
      text-align: center;
      float: left;
      width: 100%;
    }
  }


  .footer-widget-area div > ul {
    padding-left: 0px;
    list-style: none;
    margin-bottom: 0px;
    text-transform: uppercase;
    > li > a {
      color: @brand-text-on-dark;
      font-size: @font-size-h5;
      font-weight: @headings-font-weight;
      padding-bottom: (@grid-gutter-width * @heading-margin-factor * .25);
      border-bottom: 2px solid @brand-primary;
      margin-bottom: (@grid-gutter-width * @heading-margin-factor * .75);
    }
    li {
      padding-bottom: (@grid-gutter-width * @heading-margin-factor * .5);
      padding-top: (@grid-gutter-width * @heading-margin-factor * .125);
      font-weight: 600;
      font-family: @headings-font-family;
      a {
        color: @brand-text-on-dark;
      }
      li {
        //padding-left: 1em;
        ul {
          list-style-type: square;
          padding-left: 1.225em;
        }
        li {
          color: @brand-primary;
          padding-bottom: (@grid-gutter-width * @heading-margin-factor * 0);
          padding-top: (@grid-gutter-width * @heading-margin-factor * .375);
          text-transform: none;

          font-size: (1em - 0.142857143);
          &:before {

          }
        }
      }
      a {
        display: inline-block;
        width: 100%;
        //padding: 0em;
        &:hover {
          text-decoration: none;
        }
      }
      ul {
        padding-left: 0px;
        list-style: none;
        margin-bottom: 0px;
        text-transform: uppercase;
        font-size: 1em;
        li {

          margin-bottom: 0em;
          a {

          }
        }
      }
    }
  }
  .social-links {
    padding-top: (@grid-gutter-width * @paragraph-margin-factor);
    padding-bottom: (@grid-gutter-width * @paragraph-margin-factor);
    text-align:center;
    border-top: 1px solid @brand-gray;
    border-bottom: 1px solid @brand-gray;

    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);
    a.social-link {
      color: #fff;
      margin-right: .25em;
      text-shadow:1px 1px @brand-gray-dark, 2px 2px @brand-primary;
      i {
        color: inherit !important;
      }
      background: @brand-primary;
      border: 1px solid @brand-primary;
      transition: background @transition-time-global;
      &:hover {
        text-shadow:1px 1px @brand-gray-dark, 2px 2px @brand-primary;
        background: @brand-gray-dark;
      }
    }
  }
}
.center-footer {
  background: url('../images/footer-bg.svg'), @brand-gray-dark;
  background-position: bottom right !important;
  background-size: contain !important;
  background-repeat: no-repeat;
}
.after-footer {
  p {
    margin-bottom: 0px;
  }
}