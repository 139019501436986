@xs-pad: .25;
@sm-pad: .5;
@md-pad: 1;
@lg-pad: 1.5;
@xl-pad: 2;
@no-pad: 0;
@reg-pad: .25;

.pad-mixin3(@size,@axis) {
  @c: ~"@{size}";
  .pad@{c}, div.pad@{c}, .row.pad@{c}, .container.pad@{c} {
    padding: (@axis * @grid-gutter-width );
    > .row {
      margin-left: (@grid-gutter-width * @axis * -1);
      margin-right: (@grid-gutter-width * @axis * -1);
    }
  }
  .pad@{c}-horz, div.pad@{c}-horz, .row.pad@{c}-horz, .container.pad@{c}-horz {
    padding-left: (@axis * @grid-gutter-width );
    padding-right: (@axis * @grid-gutter-width );
    > .row {
      margin-left: (@grid-gutter-width * @axis * -1);
      margin-right: (@grid-gutter-width * @axis * -1);
    }
  }
  .pad@{c}-vert, div.pad@{c}-vert, .row.pad@{c}-vert, .container.pad@{c}-vert {
    padding-top: (@axis * @grid-gutter-width );
    padding-bottom: (@axis * @grid-gutter-width );
  }
  section.pad@{c}-vert {
    padding-top: ((@axis * @grid-gutter-width ) - (@grid-gutter-width / 4));
    padding-bottom: ((@axis * @grid-gutter-width ) - (@grid-gutter-width / 4));
  }
  .pad@{c}-top, div.pad@{c}-top, .row.pad@{c}-top, .container.pad@{c}-top {
    padding-top: (@axis * @grid-gutter-width );
    padding-bottom: (@axis * @grid-gutter-width );
  }
  section.pad@{c}-top {
    padding-top: ((@axis * @grid-gutter-width ) - (@grid-gutter-width / 4));
  }
  .pad@{c}-bottom, div.pad@{c}-bottom, .row.pad@{c}-bottom, .container.pad@{c}-bottom {
    padding-bottom: (@axis * @grid-gutter-width );
    padding-bottom: (@axis * @grid-gutter-width );
  }
  section.pad@{c}-bottom {
    padding-bottom: ((@axis * @grid-gutter-width ) - (@grid-gutter-width / 4));
  }
}

.pad-mixin3(~'-xs', @xs-pad);
.pad-mixin3(~'-sm', @sm-pad);
.pad-mixin3(~'-md', @md-pad);
.pad-mixin3(~'-lg', @lg-pad);
.pad-mixin3(~'-xl', @xl-pad);
.pad-mixin3(~'-no', @no-pad);
.pad-mixin3(~'-none', @no-pad);
.pad-mixin3(~'-0', @no-pad);
.pad-mixin3(~'-reg', @reg-pad);
.pad-mixin3(~'', @reg-pad);

//--- Screensize padding mixin

.pad-mixin-screen-size-thing(@screensize,@screensize-min,@size,@padding-factor) {
  @padding-size: ~"@{size}";
  @scsize-name: ~"@{screensize}";
  @media ( min-width: @screensize-min) {
    .pad-@{scsize-name}-@{padding-size}, div.pad-@{scsize-name}-@{padding-size}, .container.pad-@{scsize-name}-@{padding-size} {
      padding: (@padding-factor * @grid-gutter-width );
      > .row {
        margin-left: (@grid-gutter-width * @padding-factor * -1);
        margin-right: (@grid-gutter-width * @padding-factor * -1);
      }
    }

    .pad-@{scsize-name}-@{padding-size}-horz, div.pad-@{scsize-name}-@{padding-size}-horz, .container.pad-@{scsize-name}-@{padding-size}-horz {
      padding-left: (@padding-factor * @grid-gutter-width );
      padding-right: (@padding-factor * @grid-gutter-width );
      > .row {
        margin-left: (@grid-gutter-width * @padding-factor * -1);
        margin-right: (@grid-gutter-width * @padding-factor * -1);
      }
    }

    .pad-@{scsize-name}-@{padding-size}-vert, section.pad-@{scsize-name}-@{padding-size}-vert div.pad-@{scsize-name}-@{padding-size}-vert, .row.pad-@{scsize-name}-@{padding-size}-vert, .container.pad-@{scsize-name}-@{padding-size}-vert {
      padding-top: (@padding-factor * @grid-gutter-width );
      padding-bottom: (@padding-factor * @grid-gutter-width );
    }

    .pad-@{scsize-name}-@{padding-size}-top, div.pad-@{scsize-name}-@{padding-size}-top, .row.pad-@{scsize-name}-@{padding-size}-top, .container.pad-@{scsize-name}-@{padding-size}-top {
      padding-top: (@padding-factor * @grid-gutter-width );
    }

    .pad-@{scsize-name}-@{padding-size}-bottom, div.pad-@{scsize-name}-@{padding-size}-bottom, .row.pad-@{scsize-name}-@{padding-size}-bottom, .container.pad-@{scsize-name}-@{padding-size}-bottom {
      padding-bottom: (@padding-factor * @grid-gutter-width );
    }

    .pad-@{scsize-name}-@{padding-size}-left, div.pad-@{scsize-name}-@{padding-size}-left, .container.pad-@{scsize-name}-@{padding-size}-left {
      padding-left: (@padding-factor * @grid-gutter-width );
    }

    .pad-@{scsize-name}-@{padding-size}-right, div.pad-@{scsize-name}-@{padding-size}-right, .container.pad-@{scsize-name}-@{padding-size}-right {
      padding-right: (@padding-factor * @grid-gutter-width );
    }

    section.pad-@{scsize-name}-@{padding-size},
    section.pad-@{scsize-name}-@{padding-size}-horz,
    section.pad-@{scsize-name}-@{padding-size}-left,
    section.pad-@{scsize-name}-@{padding-size}-right{
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@pad-factor-0: .0;
@pad-factor-1: .25;
@pad-factor-2: .5;
@pad-factor-3: 1;
@pad-factor-4: 1.5;
@pad-factor-5: 2;
@pad-factor-6: 2.5;
@pad-factor-7: 3;

.pad-mixin-screen-size-thing(~'xs', 0, ~'1', @pad-factor-1);
.pad-mixin-screen-size-thing(~'xs', 0, ~'2', @pad-factor-2);
.pad-mixin-screen-size-thing(~'xs', 0, ~'3', @pad-factor-3);
.pad-mixin-screen-size-thing(~'xs', 0, ~'4', @pad-factor-4);
.pad-mixin-screen-size-thing(~'xs', 0, ~'5', @pad-factor-5);
.pad-mixin-screen-size-thing(~'xs', 0, ~'6', @pad-factor-6);
.pad-mixin-screen-size-thing(~'xs', 0, ~'7', @pad-factor-7);
.pad-mixin-screen-size-thing(~'xs', 0, ~'0', @pad-factor-0);

.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'1', @pad-factor-1);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'2', @pad-factor-2);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'3', @pad-factor-3);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'4', @pad-factor-4);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'5', @pad-factor-5);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'6', @pad-factor-6);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'7', @pad-factor-7);
.pad-mixin-screen-size-thing(~'sm', @screen-sm-min, ~'0', @pad-factor-0);

.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'1', @pad-factor-1);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'2', @pad-factor-2);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'3', @pad-factor-3);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'4', @pad-factor-4);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'5', @pad-factor-5);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'6', @pad-factor-6);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'7', @pad-factor-7);
.pad-mixin-screen-size-thing(~'md', @screen-md-min, ~'0', @pad-factor-0);

.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'1', @pad-factor-1);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'2', @pad-factor-2);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'3', @pad-factor-3);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'4', @pad-factor-4);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'5', @pad-factor-5);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'6', @pad-factor-6);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'7', @pad-factor-7);
.pad-mixin-screen-size-thing(~'lg', @screen-lg-min, ~'0', @pad-factor-0);



/// text-alignment mixins ------------------





.type-alignment-screensize-mixin(@screensizename,@screensize-min) {
  @scsize-name: ~"@{screensizename}";
  @media ( min-width: @screensize-min) {
    .text-@{scsize-name}-last-center{
      text-align-last: center;
    }
    .text-@{scsize-name}-last-right{
      text-align-last: right;
    }
    .text-@{scsize-name}-last-left{
      text-align-last: left;
    }
    .text-@{scsize-name}-center{
      text-align: center;
    }
    .text-@{scsize-name}-justify{
      text-align: justify;
    }
    .text-@{scsize-name}-right{
      text-align: right;
    }
    .text-@{scsize-name}-left{
      text-align: left;
    }
  }
}

.type-alignment-screensize-mixin(~'xs', 0);
.type-alignment-screensize-mixin(~'sm', @screen-sm-min);
.type-alignment-screensize-mixin(~'md', @screen-md-min);
.type-alignment-screensize-mixin(~'lg', @screen-lg-min);