#content section{
  .row {
    &.home-icon-border-row {
      padding-top: @grid-gutter-width;
      //padding-bottom: @grid-gutter-width;

      .column {
        padding: @grid-gutter-width @grid-gutter-width @grid-gutter-width @grid-gutter-width;
        border-width: 0 0 0 0;
        border-style: solid;
        border-top-width: @border-column-width;
        img {
          max-width: 160px;
        }

      }
      @media( min-width: @screen-sm-min) {
        display: flex;
        align-items: stretch;
        flex-wrap:wrap;
        justify-content: center;
        .column {
          h2 {
            min-height: 3.5em;
          }
          img {
            max-width: 260px;
          }
          border-top-width: 0;
          width: 50%;
          text-align: center;
          border-width: 0 0 0 0;
          &:nth-child(2),&:nth-child(1){
            border-bottom-width: @border-column-width;
          }
          &:nth-child(2){
            border-left-width: @border-column-width;
          }
          &:last-child {

          }
          img {
            max-width :200px;
          }
          padding: (@grid-gutter-width );

        }
      }
      @media( min-width: @screen-md-min) {
        display: flex;
        align-items: stretch;
       .column {
         text-align: center;
         width: 33%;
         border-width: 0 0 0 0;
         border-bottom-width: 0px !important;
         border-left-width: @border-column-width;
         border-style: solid;

         img {
           max-width :200px;
         }
         padding: (@grid-gutter-width );
         &:first-child {
           border-left-width: 0px;
           padding-left: (@grid-gutter-width / 2);
           padding-right: (@grid-gutter-width * 1.5)
         }
         &:last-child {
           padding-right: (@grid-gutter-width / 2);
           padding-left: (@grid-gutter-width * 1.5)
         }
       }
      }
    }
  }
}

#content section {
  div.row.img-limi-250 {
    .column {
      .pt-image {
        width: 100%;
        text-align:center;
        img {
          display: inline-block;
          max-width: 130px;
          margin-bottom: 1em;
        }
      }
    }
  }
}

.text-object.testimonial-block {
  blockquote {
    padding: 0 1em 0 1em;
    @media(max-width: @screen-xs-max) {
      padding-left: 0px;
      padding-right: 0px;
    }
    border-width: 0 0 0 0;
    font-family: @headings-font-family;
    font-weight: 500;
    text-align: center;
    p {
      strong {

      }
      &:last-child {
        margin-top: 1em;
        line-height: 1em;


      }
    }
  }
}